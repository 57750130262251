<template>
  <div ref="widgetContentContainer" :class="classList">
    <v-row justify="center" class="fs-unmask">
      <v-col
        md="12"
        sm="10"
        class="cob-container">
        <transition mode="out-in" name="slide">
          <consumer-loan-apply-wizard-complete-application-later-msg
            v-if="isCompleteLaterClicked"
            :email="email" />

          <consumer-loan-apply-wizard-application-canceled-msg v-else-if="applicationCanceled" />

          <consumer-invalid-link-msg v-else-if="isInvalidLink" />

          <consumer-expired-link-msg v-else-if="expiredLink" />

          <consumer-loan-apply-wizard-reentry-step
            v-else-if="reEntryActivated"
            @codeSent="navigateToStepThree" />

          <request-loan-amount-step
            v-else-if="wizardStep === 1"
            :data="data"
            @nextStep="navigateStep" />

          <contact-information-step
            v-else-if="wizardStep === 2"
            :data="data"
            @nextStep="navigateStep" />

          <otp-step
            v-else-if="wizardStep === 3"
            :data="data"
            :show-header="showHeader"
            @nextStep="navigateStep" />

          <name-step
            v-else-if="wizardStep === 4"
            @nextStep="navigateStep" />

          <address-step
            v-else-if="wizardStep === 5"
            @nextStep="navigateStep" />

          <personal-information-step
            v-else-if="wizardStep === 6"
            @nextStep="navigateStep" />

          <employment-information-step
            v-else-if="wizardStep === 7"
            @nextStep="navigateStep" />

          <individual-income-step
            v-else-if="wizardStep === 8 && isNewEmploymentFlowEnabled"
            @nextStep="navigateStep" />

          <docv-verification-step
            v-else-if="wizardStep === 9"
            :data="data"
            @nextStep="navigateStep" />

          <review-information-step
            v-else-if="wizardStep === 10"
            :data="data"
            @nextStep="navigateStep" />

          <offers-step
            v-else-if="wizardStep === 11"
            :data="data"
            @nextStep="navigateStep" />

          <capture-id-step
            v-else-if="wizardStep === 12"
            @nextStep="navigateStep" />

          <process-loan-step
            v-else-if="wizardStep === 13"
            @nextStep="navigateStep" />

          <loan-acceptance-step
            v-else-if="wizardStep === 14"
            @nextStep="navigateStep" />

          <payment-portal-setup-step
            v-else-if="wizardStep === 15" />
        </transition>
      </v-col>
    </v-row>

    <alert-modal
      :dialog="showErrorAlertModal"
      :hide-close="true"
      type="error">
      {{ errorResponseAlertModal }}
    </alert-modal>
  </div>
</template>

<script lang="ts">
import WidgetsMixin from '@/mixins/Widgets/WidgetsMixin';
import AlertModal from '@/components/Dialogs/AlertModal.vue';
import FeatureFlagsConstants from '@/constants/FeatureFlagsConstants';
import { honeypot } from 'honeypot-run';
import SocureIDMixin from '@/mixins/SocureIDMixin';
import { defineComponent } from 'vue';
import IdleTimeoutMixin from '@/mixins/IdleTimeoutMixin';
import ReEntryMixin from '@/mixins/Consumer/LoanApply/ReEntryMixin';
import GetErrors from '@/mixins/GetErrors';
import FeatureFlagsMixin from '@/mixins/FeatureFlagsMixin';
import { ConsumerInvitationInterface } from '@/interfaces/consumer/ConsumerInvitationInterface';
import ConsumerLoanApplyWizardApplicationCanceledMsg
  from '@/components/Consumer/LoanApplyWizard/Messages/ApplicationCanceledMsg.vue';
import ConsumerLoanApplyWizardCompleteApplicationLaterMsg
  from '@/components/Consumer/LoanApplyWizard/Messages/CompleteApplicationLaterMsg.vue';
import ConsumerInvalidLinkMsg from '@/components/Consumer/ConsumerInvalidLinkMsg.vue';
import ConsumerExpiredLinkMsg from '@/components/Consumer/ConsumerExpiredLinkMsg.vue';
import RequestLoanAmountStep from '@/components/Consumer/LoanApplyWizard/RequestLoanAmountStep.vue';
import ContactInformationStep from '@/components/Consumer/LoanApplyWizard/ContactInformationStep.vue';
import OtpStep from '@/components/Consumer/LoanApplyWizard/OtpStep.vue';
import NameStep from '@/components/Consumer/LoanApplyWizard/NameStep.vue';
import AddressStep from '@/components/Consumer/LoanApplyWizard/AddressStep.vue';
import PersonalInformationStep from '@/components/Consumer/LoanApplyWizard/PersonalInformationStep.vue';
import EmploymentInformationStep from '@/components/Consumer/LoanApplyWizard/EmploymentInformationStep.vue';
import DocvVerificationStep from '@/components/Consumer/LoanApplyWizard/DocvVerificationStep.vue';
import ReviewInformationStep from '@/components/Consumer/LoanApplyWizard/ReviewInformationStep.vue';
import OffersStep from '@/components/Consumer/LoanApplyWizard/OffersStep.vue';
import CaptureIdStep from '@/components/Consumer/LoanApplyWizard/CaptureIDStep.vue';
import ProcessLoanStep from '@/components/Consumer/LoanApplyWizard/ProcessLoanStep.vue';
import LoanAcceptanceStep from '@/components/Consumer/LoanApplyWizard/LoanAcceptanceStep.vue';
import PaymentPortalSetupStep from '@/components/Consumer/LoanApplyWizard/PaymentPortalSetupStep.vue';
import ConsumerLoanApplyWizardReentryStep from '@/components/Consumer/LoanApplyWizard/ReEntryFirstStep.vue';
import LocalStorageConstants from '@/constants/LocalStorageConstants';
import { useConsumerOnboarding } from '@/composables/useConsumerOnboarding';
import IndividualIncomeStep from '@/components/Consumer/LoanApplyWizard/IndividualIncomeStep.vue';

export default defineComponent({
  name: 'ConsumerLoanApplyWizard',
  components: {
    ConsumerLoanApplyWizardApplicationCanceledMsg,
    ConsumerLoanApplyWizardCompleteApplicationLaterMsg,
    ConsumerInvalidLinkMsg,
    ConsumerExpiredLinkMsg,
    RequestLoanAmountStep,
    ContactInformationStep,
    OtpStep,
    NameStep,
    AddressStep,
    PersonalInformationStep,
    EmploymentInformationStep,
    DocvVerificationStep,
    ReviewInformationStep,
    OffersStep,
    CaptureIdStep,
    ProcessLoanStep,
    LoanAcceptanceStep,
    PaymentPortalSetupStep,
    ConsumerLoanApplyWizardReentryStep,
    IndividualIncomeStep,
    AlertModal,
  },
  mixins: [
    WidgetsMixin,
    IdleTimeoutMixin,
    ReEntryMixin,
    GetErrors,
    FeatureFlagsMixin,
    SocureIDMixin,
  ],

  setup() {
    const { getInvitation } = useConsumerOnboarding();
    return { getInvitation };
  },

  data() {
    return {
      baseClass: 'standard-wizard',
      show: false,
      showHeader: false,
      reEntryActivated: false,
      showInvalidLinkMsg: false,
      data: {},
    };
  },
  computed: {
    isCompleteLaterClicked(): boolean {
      return this.$store.getters['Consumer/getCompleteLaterClicked'];
    },
    applicationCanceledData(): any {
      return this.$store.getters['Consumer/getApplicationCanceledData'];
    },
    applicationCanceled(): any {
      return this.applicationCanceledData.canceled;
    },
    classList(): string {
      const classList: Array<string> = [this.baseClass];
      classList.push(`${this.baseClass}--consumer-loan-apply`);
      return classList.join(' ');
    },
    wizardStep(): number {
      return this.$store.getters['Consumer/getLoanApplyWizardStep'];
    },
    expiredLink(): boolean {
      return this.$store.getters['Consumer/getExpiredLink'] ?? false;
    },
    merchantId(): string {
      return this.invitation?.merchant;
    },
    email(): string {
      return this.$store.getters['Consumer/getLoanApplyWizardData'].email || '';
    },
    invitation(): ConsumerInvitationInterface {
      return this.$store.getters['Consumer/getInvitation'];
    },
    isInvalidLink(): boolean {
      return (this.invitation.uuid && !this.merchantId) || this.showInvalidLinkMsg;
    },
    isNewEmploymentFlowEnabled() {
      return this.isFeatureEnabled(FeatureFlagsConstants.ENABLE_NEW_EMPLOYMENT_FLOW);
    },
  },
  watch: {
    applicationCanceled: {
      handler(newVal: any) {
        if (newVal) {
          this.$emit('hideDividerOnHeader', newVal);
        }
      },
      immediate: true,
    },
  },
  async created() {
    await this.$store.dispatch('Ui/getConsumerBrandingData');
  },
  mounted() {
    this.setup();

    // Honeypot identification setup
    if (window.honeypot?.didInit) {
      honeypot.identify(this.merchantId);
    }

    this.mountDeviceSessionScript();
  },
  methods: {
    async setup() {
      const invitationUuid = sessionStorage.getItem(LocalStorageConstants.COB_INVITATION_UUID);
      if (!invitationUuid) {
        this.showInvalidLinkMsg = true;
        return;
      }
      const status = await this.getInvitation(invitationUuid);
      if (status === 401) {
        // Get invitation without JWT token
        await this.getInvitation(invitationUuid);
      }

      const {
        app_status_detail: appStatusDetail,
        user_id: userId,
        app_id: appId,
      } = this.invitation;

      // If the application has a status detail at this point, it is in re-entry
      if (appStatusDetail) {
        this.setupReEntryStep();

        // userId is only used to verify that the JWT token is still valid and that
        // we don't need to re-authenticate the user via OTP
        if (userId) {
          // If the token is valid we set the necessary data for re-entry
          this.$store.dispatch('Consumer/setConsumerApplicationId', appId);
          await this.$store.dispatch('Consumer/getExistingApplication');
          this.navigateStep(this.ReEntryStep);
        } else {
          // If the token is invalid we show the re-entry OTP step where the above
          // data setting will be done
          this.reEntryActivated = true;
        }
        return;
      }

      this.navigateStep(1);
    },
    setStep(step: number): void {
      this.$store.commit('Consumer/setLoanApplyWizardStep', step);
    },
    navigateStep(step: number): void {
      window.scrollTo(0, 0);

      if (step) {
        if (step === 12 && !this.isFeatureEnabled(FeatureFlagsConstants.CAPTURE_ID)) {
          step += 2;
        }

        this.setStep(step);
      }
    },
    goToPreviousStep(): void {
      this.navigateStep(this.wizardStep - 1);
    },
    navigateToStepThree() {
      this.showHeader = true;
      this.navigateStep(3);
      this.reEntryActivated = false;
    },
    hideDivider(val: boolean) {
      this.$emit('hideDividerOnHeader', val);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/_custom-transitions";

.card {
  min-height: 300px;
  background-color: #FFF;
}

.cob-container {
  max-width: 28.25rem;
  padding: 0 1rem;
}

@include mobile {
  .complete-this-later {
    margin-bottom: 4rem;
  }
}

// This is a hacky way to do it before we implement vuetify buttons.
.standard-form-button {
  height: 1.875rem;
  font-size: 0.75rem;
}

:deep() .v-input {
  .v-input__details {
    margin-bottom: 0.5rem;
  }
}
</style>

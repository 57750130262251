<template>
  <div class="fs-unmask standard-wizard__step standard-wizard__step--contact-info" data-test="COB-contactInformationStep">
    <bubble-card>
      <template #card-title>
        <h5 data-test="contactInfoHeaderLabel">
          Contact Information
        </h5>
      </template>
      <standard-wizard-message
        v-if="!phoneResendEnabled"
        data-test="maxMsg"
        message="Sorry, we cannot verify your mobile number and you cannot proceed."
        :no-border="false" />

      <div v-else>
        <v-form class="contact-info-form" @submit.prevent="nextStep">
          <div v-if="loading" class="d-flex justify-center">
            <v-progress-circular indeterminate class="my-8" />
          </div>
          <template v-else>
            <phone-input
              v-model="stepData.phone_number"
              :error-messages="errors.phone_number"
              label="Mobile Phone Number"
              required />

            <email-input
              v-model="stepData.email"
              :is-fs-excluded="false"
              label="Email Address"
              :external-validation="emailValidationResult"
              required
              @input="emailValidationResult = null" />

            <p class="mb-8" data-test="contactInfoParagraphLabel">
              To continue your application, we will send you a
              <strong>six digit security code</strong> on your mobile device.
              Click below to send the one time code.
            </p>
          </template>

          <custom-button
            :disabled="isDisabled || btnDisabled"
            full-width
            data-test="continueBtn"
            type="submit">
            Continue
          </custom-button>
        </v-form>
      </div>
    </bubble-card>
  </div>
</template>

<script lang="ts">
import BubbleCard from '@/components/Cards/BubbleCard.vue';
import EmailInput from '@/components/Inputs/Email.vue';
import PhoneInput from '@/components/Inputs/Phone.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import StandardWizardMessage from '@/components/Wizard/Message.vue';
import { LoanApplyDataInterface } from '@/interfaces/consumer/loanApply/LoanApplyDataInterface';
import GetErrors from '@/mixins/GetErrors';
import OtpMixin from '@/mixins/OtpMixin';
import emailAddressValidator from '@/validators/email_address';
import phoneNumberValidator from '@/validators/phone_number';
import { get } from 'lodash';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import { validateEmail as validationEndpoint } from '@/api/address';
import { EmailValidationResult } from '@/interfaces/addressValidation/AddressDataInterface';
import { defineComponent } from 'vue';
import { PageTypesShorthand } from '@/enums/PageTypes';

interface Data {
  stepData: LoanApplyDataInterface;
  emailValidationResult: EmailValidationResult | null;
  ownStep: number;
  btnDisabled: boolean;
  hpEventName: string;
  hpStep: number;
  loading: boolean;
}

export default defineComponent({
  name: 'ContactInformationStep',
  components: {
    BubbleCard,
    CustomButton,
    StandardWizardMessage,
    EmailInput,
    PhoneInput,
  },
  mixins: [
    GetErrors,
    OtpMixin,
    NavigatesStepsMixin,
    HoneypotTrackMixin,
  ],
  data(): Data {
    return {
      stepData: {
        phone_number: '',
        email: '',
      },
      ownStep: 2,
      btnDisabled: false,
      hpEventName: 'Contact Information Page Visit',
      hpStep: 2,
      loading: false,
      emailValidationResult: null,
    };
  },
  computed: {
    isDisabled(): boolean {
      return !phoneNumberValidator(get(this.stepData, 'phone_number', ''))
        || !emailAddressValidator(get(this.stepData, 'email', ''))
        || this.loading;
    },
  },
  mounted() {
    const { email, phone } = this.$store.getters['Consumer/getInvitation'];

    if (phone) {
      this.stepData.phone_number = phone;
    }

    if (email) {
      this.stepData.email = email;
    }

    this.trackEvent(this.hpEventName, PageTypesShorthand.COB, this.hpStep);
  },
  methods: {
    async nextStep(): Promise<void> {
      this.loading = true;
      this.emailValidationResult = null;

      const emailValidation = await this.validateEmail(this.stepData.email || '');

      if (!emailValidation.is_valid) {
        this.emailValidationResult = emailValidation;
        this.loading = false;
        return;
      }

      this.btnDisabled = true;
      this.$store.commit('Consumer/setLoanApplyWizardData', this.stepData);

      const { error } = await this.$store.dispatch(
        'Consumer/otpRegister',
        this.stepData.phone_number,
      );

      if (!error) {
        this.goToStep(this.ownStep + 1);
      }

      this.loading = false;
      this.btnDisabled = false;
    },
    async validateEmail(email: string): Promise<EmailValidationResult> {
      try {
        const { data } = await validationEndpoint(email);
        return data;
      } catch (e) {
        return {
          is_valid: true,
        };
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-wizard";

.contact-info-form {
  padding-top: 0.5rem;
}
</style>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="18"
    viewBox="0 0 23 18"
    fill="none">
    <path
      d="M11.3999 3.78L18.9299 16.1053H3.8699L11.3999 3.78ZM11.3999 0L0.399902
      18H22.3999L11.3999 0ZM12.3999 13.2632H10.3999V15.1579H12.3999V13.2632ZM12.3999
      7.57895H10.3999V11.3684H12.3999V7.57895Z"
      :fill="props.fill" />
  </svg>
</template>

<script setup lang="ts">
const props = defineProps({
  fill: { type: String, default: 'var(--grayscale-color-1)' },
});
</script>

<template>
  <div
    class="fs-unmask standard-wizard__step standard-wizard__step--loan-acceptance"
    data-test="COB-loanAcceptanceStep">
    <standard-progress-indicator v-if="!loanAgreementFetched" type="circular" />
    <transition
      v-else
      mode="out-in"
      name="fade">
      <consumer-loan-apply-wizard-processing-loan-offers v-if="fetchingData && finalizingLoan">
        <span>
          Now finalizing your loan.<br>
          Please remain on this screen
          and do not refresh your browser until this process has completed.
        </span>
      </consumer-loan-apply-wizard-processing-loan-offers>

      <consumer-loan-apply-wizard-application-not-accepted v-else-if="applicationNotAccepted" />

      <div v-else-if="onboardsConsumerPortal" class="px-1">
        <finalize-loan />
      </div>

      <div v-else class="px-1">
        <h6 class="mt-4">
          To Finalize Your Loan, Complete Everything Below.
        </h6>
        <checkbox-input
          v-model="checkLoanDoc"
          data-test="checkLoanDoc"
          class="checkbox mt-6 pt-0 standard-input-field standard-input-field--checkbox">
          <template #label>
            By checking this box, I have reviewed my
            <a
              :href="loanAgreementUrl"
              target="_blank"
              rel="noopener noreferrer"
              @click.stop>
              Loan Documents
            </a>
          </template>
        </checkbox-input>
        <checkbox-input
          v-model="checkESign"
          data-test="checkESign"
          class="checkbox mt-6 pt-0 standard-input-field standard-input-field--checkbox">
          <template #label>
            The originating bank on your loan is {{ lenderName }}.
            By checking this box, I accept my loan agreement with {{ lenderName }} and their
            <a
              :href="lenderPrivacyPolicyUrl"
              rel="noopener noreferrer"
              target="_blank"
              @click.stop>
              Privacy Notice
            </a>
          </template>
        </checkbox-input>
        <p class="mb-0 mt-6">
          {{ selectedLoan.short_description }}
        </p>

        <custom-button
          class="mt-6"
          :disabled="!checked || !loanAgreementFetched"
          full-width
          label="I Accept This Loan Agreement"
          @click="nextStep" />

        <complete-later-button class="mt-12" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import CheckboxInput from '@/components/Inputs/Checkbox.vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import ConsentEntityTypes from '@/enums/Consent/EntityTypesEnum';
import ConsentTypesEnum from '@/enums/Consent/TypesEnum';
import CreditEngineHardPullApprovedAmountMixin
  from '@/mixins/Consumer/LoanApply/CreditEngineHardPullApprovedAmountMixin';
import GetErrors from '@/mixins/GetErrors';
import GetHumanReadableIdLabelMixin from '@/mixins/GetHumanReadableIdLabelMixin';
import GetProcessing from '@/mixins/GetProcessing';
import { patchAvailableConsents, postConsentTypes } from '@/utils/Consents';
import { approveAndAccept } from '@/api/consumer';
import NavigatesStepsMixin from '@/mixins/NavigatesStepsMixin';
import FinalizeLoan from '@/components/Consumer/LoanApplyWizard/FinalizeLoan/FinalizeLoan.vue';
import StandardProgressIndicator from '@/components/ProgressIndicator.vue';
import HoneypotTrackMixin from '@/mixins/HoneypotTrackMixin';
import ConsumerLoanApplyWizardApplicationNotAccepted
  from '@/components/Consumer/LoanApplyWizard/Messages/ApplicationNotAccepted.vue';
import ConsumerLoanApplyWizardProcessingLoanOffers
  from '@/components/Consumer/LoanApplyWizard/Messages/ProcessingLoanOffers.vue';
import { defineComponent } from 'vue';
import { PageTypesShorthand } from '@/enums/PageTypes';
import CompleteLaterButton from '@/components/Buttons/CompleteLaterButton.vue';

export default defineComponent({
  name: 'LoanAcceptanceStep',
  components: {
    CheckboxInput,
    CompleteLaterButton,
    CustomButton,
    ConsumerLoanApplyWizardApplicationNotAccepted,
    ConsumerLoanApplyWizardProcessingLoanOffers,
    FinalizeLoan,
    StandardProgressIndicator,
  },
  mixins: [
    GetProcessing,
    GetErrors,
    GetHumanReadableIdLabelMixin,
    CreditEngineHardPullApprovedAmountMixin,
    NavigatesStepsMixin,
    HoneypotTrackMixin,
  ],
  data() {
    return {
      ownStep: 14,
      checkESign: false,
      checkLoanDoc: false,
      applicationNotAccepted: false,
      fetchingData: false,
      finalizingLoan: false,
      loanAgreementFetched: false,
      hpEventName: 'Loan Accept Page Visit',
      hpStep: 14,
    };
  },
  computed: {
    consentTypes(): Array<number> {
      return [
        ConsentTypesEnum.WARM_WELCOME,
        ConsentTypesEnum.BORROWER_AGREEMENT,
      ];
    },
    selectedLoan(): any {
      return this.$store.getters['Consumer/getSelectedLoan'];
    },
    humanReadableApplicationIdLabel() {
      return this.getHumanReadableIdLabel(this.humanReadableApplicationId);
    },
    loanAgreementUrl(): string {
      const loanAgreement = this.$store.getters['Consumer/getLoanAgreement'];
      if (!loanAgreement) return '';

      const file = new File([loanAgreement], 'loan-agreement.pdf', { type: loanAgreement.type });
      return URL.createObjectURL(file);
    },
    humanReadableApplicationId(): any {
      return this.$store.getters['Consumer/getConsumerHumanReadableApplicationId'];
    },
    checked(): boolean {
      return this.checkESign && this.checkLoanDoc;
    },
    lenderName(): string {
      return this.$store.getters['Consumer/getLenderName'];
    },
    lenderPrivacyPolicyUrl(): string {
      return this.$store.getters['Consumer/getLenderPrivacyPolicyUrl'];
    },
    appId(): string {
      return this.$store.getters['Consumer/getConsumerApplicationId'];
    },
    onboardsConsumerPortal(): boolean {
      return this.$store.getters['Consumer/getOnboardingDetails'].shouldCreateAccount;
    },
  },
  watch: {
    loanAgreementUrl: {
      handler(val: any) {
        this.fetchingData = !val;
      },
      immediate: true,
    },
  },
  async created() {
    await this.$store.dispatch('Consumer/getOnboardingDetails');

    await this.fetchLoanAgreement();
    this.loanAgreementFetched = true;
  },
  async mounted() {
    this.trackEvent(this.hpEventName, PageTypesShorthand.COB, this.hpStep);
  },
  methods: {
    async nextStep() {
      this.finalizingLoan = true;
      try {
        this.updateProcessStatus(true);
        await approveAndAccept(this.appId);
        this.goToStep(this.ownStep + 1);
        await this.updateConsents();
        this.updateProcessStatus(false);
      } catch (error: any) {
        this.$store.dispatch('Ui/setErrors', error.response);
        this.applicationNotAccepted = false;
      }
      this.fetchingData = false;
      this.finalizingLoan = false;
    },
    updateProcessStatus(status: boolean) {
      this.fetchingData = status;
      this.$store.commit('Consumer/setApplicationLoading', status);
    },
    updateConsents(): Promise<number> {
      return patchAvailableConsents(this.consentTypes, this.appId, ConsentEntityTypes.CONSUMER);
    },
    fetchLoanAgreement() {
      return this.$store.dispatch('Consumer/getLoanAgreement');
    },
    async presentConsent(): Promise<void> {
      await postConsentTypes({
        consentTypes: this.consentTypes,
        consumer_application_uuid: this.appId,
        entity: ConsentEntityTypes.CONSUMER,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/standard-input-field";
@import "@/assets/scss/standard-wizard";
@import "@/assets/scss/_custom-transitions";

.card {
  background-color: #FFF;
  box-shadow: none !important;
  border: none !important;
}

.checkbox {
  display: flex;
  align-items: flex-start;
  color: var(--grayscale-color-1);
  margin-bottom: 0.625rem;
  padding-top: 1.5rem;

  :deep(.v-selection-control) {
    label {
      padding-top: 0.375rem;
      line-height: 1.5rem;
    }
  }
}

.header-info {
  margin-bottom: 2rem;
}

.card-title {
  margin-bottom: 1.5rem;
  margin-top: 0;
}

.standard-wizard-continue-button {
  font-size: 1rem !important;
  font-weight: bold !important;
}

p, .card-title {
  font-size: 0.875rem;
  line-height: 150%;
}

p {
  margin-top: 2.5rem;
}

</style>

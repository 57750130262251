import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78784eb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rate-sheets-options" }
const _hoisted_2 = {
  key: 0,
  class: "rate-sheet-simple-header"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rate_sheet_options_data_table_header = _resolveComponent("rate-sheet-options-data-table-header")!
  const _component_rate_sheet_options_data_table_legend = _resolveComponent("rate-sheet-options-data-table-legend")!
  const _component_header_with_tooltip = _resolveComponent("header-with-tooltip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_FallbackTooltip = _resolveComponent("FallbackTooltip")!
  const _component_SeventyFiveKTooltip = _resolveComponent("SeventyFiveKTooltip")!
  const _component_preferences = _resolveComponent("preferences")!
  const _component_rate_sheet_options_more_data = _resolveComponent("rate-sheet-options-more-data")!
  const _component_selector = _resolveComponent("selector")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_simple_card = _resolveComponent("simple-card")!
  const _component_custom_dialog = _resolveComponent("custom-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_simple_card, { class: "rate-sheets-options__data-table" }, _createSlots({
      body: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["simple-card__body-text", _ctx.rateSheetTable ? 'ratesheet-table' : ''])
        }, [
          (_ctx.showLegend)
            ? (_openBlock(), _createBlock(_component_rate_sheet_options_data_table_legend, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_data_table, {
            headers: _ctx.headers,
            items: _ctx.rateSheetOptions,
            loading: _ctx.processing,
            mobile: _ctx.isMobile,
            "data-test": _ctx.customDataTest,
            "items-per-page": _ctx.hidePagination ? 0 : 10,
            class: _normalizeClass(_ctx.tableClasses),
            "item-key": "rate_sheet_merchant_product_price_id",
            "row-props": _ctx.toggleRowCustomClass,
            "no-data-text": "No products available",
            "hide-default-footer": _ctx.hidePagination
          }, {
            "header.product_name": _withCtx(({ column }) => [
              _createVNode(_component_header_with_tooltip, {
                title: column.title,
                align: column.align,
                tooltip: column.tooltip,
                onClick: ($event: any) => (_ctx.activeTooltip = column)
              }, null, 8, ["title", "align", "tooltip", "onClick"])
            ]),
            "header.full_term": _withCtx(({ column }) => [
              _createVNode(_component_header_with_tooltip, {
                title: column.title,
                align: column.align,
                tooltip: column.tooltip,
                onClick: ($event: any) => (_ctx.activeTooltip = column)
              }, null, 8, ["title", "align", "tooltip", "onClick"])
            ]),
            "header.apr": _withCtx(({ column }) => [
              _createVNode(_component_header_with_tooltip, {
                title: column.title,
                align: column.align,
                tooltip: column.tooltip,
                onClick: ($event: any) => (_ctx.activeTooltip = column)
              }, null, 8, ["title", "align", "tooltip", "onClick"])
            ]),
            "header.merchant_fee": _withCtx(({ column }) => [
              _createVNode(_component_header_with_tooltip, {
                title: column.title,
                align: column.align,
                tooltip: column.tooltip,
                onClick: ($event: any) => (_ctx.activeTooltip = column)
              }, null, 8, ["title", "align", "tooltip", "onClick"])
            ]),
            "item.product_name": _withCtx(({ item }) => [
              _createElementVNode("div", {
                class: _normalizeClass({ 'has-fallback': item.has_fallback })
              }, [
                _createTextVNode(_toDisplayString(item.product_name) + " ", 1),
                (!_ctx.has75kFeatureFlag && item.has_fallback)
                  ? (_openBlock(), _createBlock(_component_v_icon, {
                      key: 0,
                      size: "1.5rem",
                      color: "var(--grayscale-color-1)",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('show-fallback-modal')))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" mdi-information-outline ")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], 2)
            ]),
            "item.product_attributes": _withCtx(({ item }) => [
              (item.has_fallback)
                ? (_openBlock(), _createBlock(_component_FallbackTooltip, { key: 0 }))
                : _createCommentVNode("", true),
              (_ctx.is75kProduct(item))
                ? (_openBlock(), _createBlock(_component_SeventyFiveKTooltip, { key: 1 }))
                : _createCommentVNode("", true)
            ]),
            "item.merchant_fee": _withCtx(({ item }) => [
              _createTextVNode(_toDisplayString(item.merchant_fee) + "% ", 1)
            ]),
            "item.customer_preferences": _withCtx(({ item }) => [
              _createVNode(_component_preferences, {
                "customer-preferences": item.customer_preferences
              }, null, 8, ["customer-preferences"])
            ]),
            "item.select": _withCtx(({ item }) => [
              (!_ctx.additionalProductOfferings && !_ctx.switchToggle)
                ? (_openBlock(), _createBlock(_component_rate_sheet_options_more_data, {
                    key: 0,
                    "merchant-onboarding": _ctx.merchantOnboarding,
                    "rate-sheet-option": item,
                    "is-mobile": _ctx.isMobile,
                    onToggle: _ctx.removeRipple
                  }, null, 8, ["merchant-onboarding", "rate-sheet-option", "is-mobile", "onToggle"]))
                : _createCommentVNode("", true),
              (_ctx.additionalProductOfferings || _ctx.switchToggle)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass({ 'd-flex more-data-action': !_ctx.isMobile })
                  }, [
                    _createVNode(_component_rate_sheet_options_more_data, {
                      "merchant-onboarding": _ctx.merchantOnboarding,
                      "rate-sheet-option": item,
                      "rate-sheet-action": "Add To Rate Sheet",
                      onToggle: ($event: any) => (_ctx.selectRateSheetOption(item))
                    }, null, 8, ["merchant-onboarding", "rate-sheet-option", "onToggle"]),
                    _createVNode(_component_selector, {
                      class: "button-full-width",
                      "button-toggle": _ctx.buttonToggle,
                      "switch-toggle": _ctx.switchToggle,
                      "disabled-add": _ctx.disableAddBtns,
                      selected: item.is_selected,
                      "additional-product-offerings": _ctx.additionalProductOfferings,
                      onToggle: ($event: any) => (_ctx.selectRateSheetOption(item))
                    }, null, 8, ["button-toggle", "switch-toggle", "disabled-add", "selected", "additional-product-offerings", "onToggle"])
                  ], 2))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["headers", "items", "loading", "mobile", "data-test", "items-per-page", "class", "row-props", "hide-default-footer"])
        ], 2)
      ]),
      _: 2
    }, [
      (_ctx.showHeader)
        ? {
            name: "header",
            fn: _withCtx(() => [
              (_ctx.simpleHeader)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("h5", null, _toDisplayString(_ctx.headerTitle), 1)
                  ]))
                : (_openBlock(), _createBlock(_component_rate_sheet_options_data_table_header, {
                    key: 1,
                    ref: "header",
                    "header-title": _ctx.headerTitle
                  }, null, 8, ["header-title"]))
            ]),
            key: "0"
          }
        : undefined
    ]), 1024),
    (_ctx.activeTooltip)
      ? (_openBlock(), _createBlock(_component_custom_dialog, {
          key: 0,
          dialog: "",
          "show-cancel-cta": false,
          title: _ctx.activeTooltip.title,
          "button-label": "OK",
          "button-size": "small",
          width: "420",
          "on-cta": _ctx.closeTooltip,
          onCancel: _ctx.closeTooltip
        }, {
          body: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.activeTooltip.tooltip), 1)
          ]),
          _: 1
        }, 8, ["title", "on-cta", "onCancel"]))
      : _createCommentVNode("", true)
  ]))
}
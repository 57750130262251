<template>
  <div>
    <div v-if="lessThanTwoProducts" class="container portal__container">
      <custom-alert type="warning">
        {{ lessThanTwoProductsMsg }}
      </custom-alert>
    </div>

    <simple-card :class="classList" class="section-card">
      <template #header>
        <rate-sheet-options-data-table-header ref="header" header-title="Your Rate Sheet" />
      </template>

      <template #body>
        <div id="selectedProducts" class="px-0 simple-card__body-text">
          <rate-sheet-options-data-table
            ref="rateSheetDataTable"
            v-model="selectedProducts"
            :show-header-info-on-mount="false"
            :items-per-page="10"
            :rate-sheet-options="selectedProductsCopy"
            :show-header="false"
            :show-legend="true"
            :ratesheet-table="true"
            pagination="off"
            @show-fallback-modal="showFallbackModal = true" />

          <simple-card>
            <template #actions>
              <custom-button
                full-width
                :disabled="saveChangesButtonDisabled"
                data-test="saveBtn"
                @click="saveChanges">
                Save Changes To Rate Sheet
              </custom-button>
            </template>
          </simple-card>
        </div>
      </template>
    </simple-card>

    <simple-card :class="additionalProductsClassList" class="section-card">
      <template #header>
        <div class="simple-card__header text-left">
          <h3 class="subheader">
            Additional Products
          </h3>
        </div>
      </template>

      <template #body>
        <div class="simple-card__body-text">
          <rate-sheet-options-expandable-categories-tables
            ref="rateSheetExpandableCategories"
            :disabled="processing"
            :rate-sheet-options="additionalProducts"
            @show-fallback-modal="showFallbackModal = true"
            @toggle="moveToBasePackage" />
        </div>
      </template>
    </simple-card>

    <simple-card class="gray-border mt-8 section-card">
      <template #body>
        <offer-codes />
      </template>
    </simple-card>

    <alert-modal
      :dialog="showAlert"
      :on-close="closeAlertDialog"
      show-cancel-button
      type="error">
      <div class="mb-6 mt-3">
        You must have at least <strong>two active loan products</strong>
        in your Rate Sheet to send New Loan Invitations.
      </div>
    </alert-modal>

    <save-or-edit-modal
      :dialog="showSaveOrEdit"
      @saveChanges="saveChanges"
      @keepEditing="$emit('closeModal')" />

    <rate-sheet-selection-modal
      v-if="showRateSheetSelectionModal"
      @cancel="showRateSheetSelectionModal = false" />

    <!-- Remove once $75k feature flag is permanent -->
    <APRCapCompliancePopup
      v-if="showFallbackModal"
      title="Deferred Interest Products, 7 Year Term"
      in-rate-sheet-table
      @close="showFallbackModal = false" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import CustomButton from '@/components/Buttons/CustomButton.vue';
import RateSheetOptionsDataTable from '@/components/RateSheetOptions/DataTable/index.vue';
import RateSheetOptionsDataTableHeader from '@/components/RateSheetOptions/DataTable/Header.vue';
import RateSheetOptionsExpandableCategoriesTables
  from '@/components/RateSheetOptions/ExpandableCategories/ExpandableCategoriesTables.vue';
import SimpleCard from '@/components/SimpleCard.vue';
import { RateSheetData } from '@/interfaces/rates/RateSheetData';
import { RateSheetsPostDataInterface } from '@/interfaces/rates/RateSheetsPostDataInterface';
import GetErrors from '@/mixins/GetErrors';
import GetProcessing from '@/mixins/GetProcessing';
import GetRateSheetPostData from '@/utils/GetRateSheetPostData';
import AlertModal from '@/components/Dialogs/AlertModal.vue';
import OfferCodes from '@/components/Merchant/Portal/RateSheets/OfferCodes/OfferCodes.vue';
import SaveOrEditModal from '@/components/Dialogs/RateSheetSave.vue';
import { GlobalAlertIface } from '@/interfaces/GlobalAlertInterface';
import GlobalAlertTypes from '@/enums/GlobalAlertTypes';
import CustomAlert from '@/components/Alerts/CustomAlert.vue';
import APRCapCompliancePopup from '@/components/Dialogs/APRCapCompliancePopup.vue';
import RateSheetSelectionModal from './RateSheetSelectionModal.vue';

function changesInProducts(products: Array<RateSheetData>): string {
  const list: string[] = [];
  products.forEach((product: RateSheetData) => {
    if (product.is_selected) {
      list.push(
        product.rate_sheet_merchant_product_price_id.toString(),
      );
    }
  });

  return list.sort().join('-');
}

export default defineComponent({
  name: 'RateSheets',
  components: {
    APRCapCompliancePopup,
    CustomButton,
    RateSheetOptionsExpandableCategoriesTables,
    RateSheetOptionsDataTableHeader,
    SimpleCard,
    RateSheetOptionsDataTable,
    OfferCodes,
    AlertModal,
    SaveOrEditModal,
    CustomAlert,
    RateSheetSelectionModal,
  },
  mixins: [
    GetProcessing,
    GetErrors,
  ],
  props: {
    showSaveOrEdit: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedRates: [] as Array<RateSheetData>,
      selectedProducts: [] as Array<RateSheetData>,
      selectedProductsCopy: [] as Array<RateSheetData>,
      baseClass: 'merchant-portal-page',
      originalSelectedRateSheetsIds: '',
      showAlert: false,
      lessThanTwoProducts: false,
      showRateSheetSelectionModal: false,
      showFallbackModal: false,
    };
  },
  computed: {
    lessThanTwoProductsMsg() {
      return 'At least two loan products must be active on your Rate Sheet before you can send an '
        + 'application. An admin on your account must activate loan products on your Rate Sheet.';
    },
    merchantData(): any {
      return this.$store.getters['MerchantPortal/getMerchantData'];
    },
    classList() {
      const classList: Array<string> = [
        this.baseClass,
        `${this.baseClass}--rate-sheets`,
        `${this.baseClass}--shadow`,
        `${this.baseClass}--extra-padding-body`,
        'rate-sheets-options__data-table',
      ];

      return classList.join(' ');
    },
    additionalProductsClassList() {
      const classList: Array<string> = [
        this.baseClass,
        `${this.baseClass}--additional-products`,
        `${this.baseClass}--rate-sheets`,
        `${this.baseClass}--shadow`,
        'rate-sheets-options__data-table',
      ];

      return classList.join(' ');
    },
    rateSheets(): Array<RateSheetData> {
      return this.$store.getters['MerchantPortal/getRateSheets'];
    },
    rateSheetsSelectedProducts(): Array<RateSheetData> {
      return this.rateSheets.filter(it => it.is_selected);
    },
    additionalProducts(): Array<RateSheetData> {
      return this.rateSheets.filter(item => !this.rateSheetsSelectedProducts.includes(item));
    },
    rateSheetsSelected(): number {
      return this.selectedProducts.filter(it => it.is_selected).length;
    },
    enableRateSheetSave(): boolean {
      return this.$store.getters['MerchantPortal/getEnableRateSheetSave'];
    },
    saveChangesButtonDisabled(): boolean {
      return (this.rateSheetsSelected < 2
        || this.processing
        || !this.changesQueued)
        && !this.enableRateSheetSave;
    },
    changesQueued(): boolean {
      const currentSelectedRateSheets: string = changesInProducts(this.selectedProducts);

      return this.originalSelectedRateSheetsIds !== currentSelectedRateSheets;
    },
  },
  watch: {
    rateSheetsSelected(val: number) {
      if (val < 2) this.showAlert = true;
      this.lessThanTwoProducts = val < 2;
    },
    saveChangesButtonDisabled: {
      handler(val: boolean) {
        this.$emit('saveButtonDisabled', val);
      },
      immediate: true,
    },
    rateSheetsSelectedProducts(val: RateSheetData[]) {
      if (val) this.selectedProductsCopy = val;
    },
  },
  async created() {
    await this.refreshRateSheets();
  },
  mounted() {
    if (this.merchantData?.merchant.has_two_or_more_products_active === false) {
      this.showRateSheetSelectionModal = true;
    }
  },
  methods: {
    moveToBasePackage(rateSheet: any) {
      this.$store.dispatch('MerchantPortal/setRSExpandableCategory', -1);

      this.$nextTick(() => {
        const index = this.selectedProductsCopy.indexOf(rateSheet);
        const item = this.selectedProductsCopy.splice(index, 1);
        this.selectedProductsCopy.push(item[0]);
        document.getElementById('selectedProducts')!.scrollIntoView({ behavior: 'smooth' });
      });
    },
    async saveChanges(): Promise<void> {
      this.$emit('closeModal');

      const selectedProducts: RateSheetsPostDataInterface = GetRateSheetPostData(this.selectedProducts);

      await this.$store.dispatch(
        'MerchantPortal/dispatchSelectRates',
        selectedProducts,
      );

      await this.refreshRateSheets();

      const status = await this.$store.dispatch('MerchantPortal/dispatchGetMerchantData');

      this.saveNotification(status === 200);
    },
    saveNotification(success: boolean) {
      const text = success
        ? 'Your Changes Have Been Saved'
        : 'Your Changes Have Not Been Saved';

      const type = success ? GlobalAlertTypes.SUCCESS : GlobalAlertTypes.ERROR;

      const payload: GlobalAlertIface = { text, type, timed: true };

      this.$store.dispatch('Ui/addGlobalAlert', payload);
    },
    async refreshRateSheets(): Promise<void> {
      await this.$store.dispatch(
        'MerchantPortal/dispatchFetchRateSheet',
      );

      this.originalSelectedRateSheetsIds = changesInProducts(this.rateSheets);

      this.selectedProducts = [...this.rateSheets];
    },
    closeAlertDialog() {
      this.showAlert = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/pages/merchant-portal-page";
@import "@/assets/scss/rate-sheet-options/rate-sheet-options-data-table";
@import '@/assets/scss/mixins/media_queries';
@import '@/assets/scss/main-fonts';

.merchant-portal-page {
  &--rate-sheets {
    border: none;

    :deep(.simple-card__actions) {
      padding: 1.25rem 1rem;
      background: var(--grayscale-color-5);
    }

    .simple-card__header {
      background-color: #FFFFFF;
      color: var(--grayscale-color-1);

      :deep(.section-header__title) strong {
        @extend .subheader;
      }

      span {
        margin-left: 4rem;
        font-weight: normal;
      }
    }
  }

  &--additional-products {
    margin-top: 2rem;
    margin-bottom: 2rem;

    .simple-card__header {
      text-align: center;
      font-weight: bold;
      justify-content: center;
      white-space: normal;

      @include up-to-desktop {
        text-align: left;
        justify-content: flex-start;
      }
    }

    :deep() {
      .v-expansion-panel {
        &::before {
          box-shadow: none;
        }

        .v-expansion-panel-title {
          @include up-to-desktop {
            padding: 1rem;
          }
        }

        .v-expansion-panel-text {
          &__wrapper {
            @include up-to-desktop {
              padding: 0;
            }
          }
        }

        .rate-sheet-options-expandable-categories {
          &__card {
            box-shadow: none;

            @include up-to-desktop {
              display: block;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
</style>
